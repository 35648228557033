import { css } from 'astroturf';
import { sortBy } from 'lodash';
import React from 'react';

import CTA from '../components/CTA';
import ExitIntent from '../components/ExitIntent';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Link from '../components/link';
import Layout from '../layout';

const styles = css`
  .intro {
    margin: 0 auto 4rem;
    max-width: 40rem;

    & h2,
    & p {
      text-align: center;
      margin-bottom: 1rem;
    }
    & ol {
      margin: 0 auto 2rem;
      width: max-content;
    }
  }

  .form {
    margin: 0 auto;
    max-width: 60rem;
    padding: 0 1rem;
    position: relative;
    @media screen and (min-width: 40em) {
      padding: 0 2rem;
    }

    & p {
      margin-top: 1rem;
      text-align: center;
      font-size: 0.9em;
    }
  }

  .button > * {
    margin: auto;
  }

  .signout {
    background: none;
    border: 0;
    display: block;
    width: 100px;
    text-align: center;
    margin: 0.25rem auto 0;
    padding: 0;
    text-decoration: underline;
    color: #0079b1;
    cursor: pointer;
    font-size: 1rem;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .selectwrap {
    margin-top: 3rem;
    margin-bottom: 3rem;

    & label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: bold;
      text-align: center;
      & em {
        font-weight: normal;
        white-space: nowrap;
      }
    }

    & select {
      background: #fff;
      position: relative;
      display: block;
      font-size: 1.25rem;
      padding: 1rem;
      margin: 0 auto;
      width: 100%;
      max-width: 60rem;
      height: 56px; /* this makes the height work for mac select */
      border-radius: 5px;
      border: 0;
      box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
        rgba(0, 0, 0, 0.1) 0px 20px 30px;
    }
  }
  .sizeOverview {
    overflow: hidden;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #0079b1;

    & .dimensions {
      position: absolute;
      left: 50%;
      top: 0;
      transform-origin: top center;
      transform: translateX(-50%) scale(0.8);
      border: 2px solid #0079b1;
      border-top: 0;
      border-radius: 0 0 2px 2px;
      color: transparent;
      padding: 2rem;
      background: transparent;

      & i {
        font-size: 0.9em;
        opacity: 0.8;
      }

      &.mine {
        border-style: dotted;
        border-width: 4px;
        border-top-width: 0;
      }

      &.xsmall {
        border-color: #7900b1;
      }
      &.small {
        border-color: #0079b1;
      }
      &.medium {
        border-color: #00b179;
      }
      &.large {
        border-color: #b10079;
      }
      &.xlarge {
        border-color: #b17900;
      }

      &:hover {
        opacity: 1 !important;
        color: #000;

        &.xsmall {
          background: #7900b188;
        }
        &.small {
          background: #0079b188;
        }
        &.medium {
          background: #00b17988;
        }
        &.large {
          background: #b1007988;
        }
        &.xlarge {
          background: #b1790088;
        }
      }
    }
  }

  .legend {
    display: flex;
    margin: 1rem 0 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;

    @media screen and (min-width: 56em) {
      flex-wrap: nowrap;
    }

    & li {
      margin: 0 0.5rem;
    }
    & li div {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      margin-right: 0.25rem;
    }
    & .xsmall {
      background: #7900b1;
    }
    & .small {
      background: #0079b1;
    }
    & .medium {
      background: #00b179;
    }
    & .large {
      background: #b10079;
    }
    & .xlarge {
      background: #b17900;
    }
  }

  canvas {
    display: block;
    position: relative;
    width: 100%;
    height: 20px;
    image-rendering: crisp-edges;
  }

  .canvasTitle {
    text-align: center;
    color: #0079b1;
    margin: 0 auto 2rem;
    max-width: 56rem;
  }

  .phlink {
    display: block;
    box-shadow: none;
    padding: 0;
    margin: 1rem auto 0;
    width: 250px;

    &:hover {
      background: none;
    }
  }
`;

const CATEGORIES = {
  xsmall: 414,
  small: 600,
  medium: 1024,
  large: 1441,
  xlarge: 99999,
};

const getCategoryClass = index => {
  if (index === 0) return styles.xsmall;
  if (index === 1) return styles.small;
  if (index === 2) return styles.medium;
  if (index === 3) return styles.large;
  if (index === 4) return styles.xlarge;
};

const getCategoryName = index => {
  if (index === 0) return 'Extra small (<414px)';
  if (index === 1) return 'Small (<600px)';
  if (index === 2) return 'Medium (<1024px)';
  if (index === 3) return 'Large (<1441px)';
  if (index === 4) return 'Extra large (1441px+)';
};
const zoomFactor = 0.8;
const cutOffPercentage = 0.05;
const drawOnCanvas = canvas => {
  canvas.setAttribute('touch-action', 'none');

  const windowWidth = window.innerWidth;
  canvas.width = windowWidth;
  canvas.height = 20;

  const ctx = canvas.getContext('2d');
  let offset = 0;
  ctx.imageSmoothingEnabled = false;
  ctx.font = '9px "Inter var"';
  ctx.strokeStyle = '#0079b1';
  ctx.fillStyle = '#0079b1';

  ctx.clearRect(0, 0, 0, windowWidth, 20);

  do {
    const length = (offset * 2) % 100 === 0 ? 12 : (offset * 2) % 50 === 0 ? 14 : 16;

    ctx.strokeStyle = (offset * 2) % 50 === 0 ? '#0079b1' : '#0079b188';

    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(parseInt(windowWidth / 2 + zoomFactor * offset, 10) + 0.5, length);
    ctx.lineTo(parseInt(windowWidth / 2 + zoomFactor * offset, 10) + 0.5, 20);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(parseInt(windowWidth / 2 - zoomFactor * offset, 10) + 0.5, length);
    ctx.lineTo(parseInt(windowWidth / 2 - zoomFactor * offset, 10) + 0.5, 20);
    ctx.stroke();

    if ((offset * 2) % 100 === 0) {
      ctx.fillText(offset * 2, parseInt(windowWidth / 2 + zoomFactor * offset, 10) + 1.5, 14);
      if (offset !== 0) {
        ctx.fillText(offset * 2, parseInt(windowWidth / 2 - zoomFactor * offset, 10) + 1.5, 14);
      }
    }

    offset += 5;
  } while (offset <= (windowWidth / 2) * (1 / zoomFactor));
};

const SORTBY = {
  OCCURENCE: 'OCCURENCE',
  SIZE: 'SIZE',
};

const sortFunction = {
  [SORTBY.OCCURENCE]: item => -item.occurence,
  [SORTBY.SIZE]: item => parseInt(item.dimensions.split('x')[0], 10),
};

class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      viewName: '',
      properties: [],
      browserData: [],
      panes: 6,
      mostOccurence: 10000,
      total: 10000,
      sortBy: SORTBY.OCCURENCE,
    };

    this.canvas;
  }

  componentDidMount() {
    const ref = this;

    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/client:platform.js';
    document.body.appendChild(script);

    function getProperties() {
      window.gapi.client
        .request({
          path: 'https://www.googleapis.com/analytics/v3/management/accountSummaries',
          headers: { 'Content-Type': 'application/json' },
        })
        .then(result => JSON.parse(result.body))
        .then(results => results.items)
        .then(items => {
          const properties = items
            .map(item => {
              const { name } = item;
              return item.webProperties.map(prop => {
                const propName = prop.name;
                return prop.profiles.map(profile => ({
                  name,
                  propName,
                  profileName: profile.name,
                  id: profile.id,
                }));
              });
            })
            .flat(Infinity);

          ref.setState({ properties });
          ref.getBrowserSizes(properties[0].id);
        })
        .catch(e => {
          console.log(e);
        });
    }

    const loadGapi = () => {
      if (window.gapi) {
        window.gapi.signin2.render('my-signin2', {
          scope: '',
          width: 240,
          height: 50,
          longtitle: true,
          theme: 'dark',
          onsuccess: getProperties,
          onfailure: () => {},
        });
      } else {
        setTimeout(loadGapi, 100);
      }
    };

    loadGapi();

    if (this.canvas) {
      drawOnCanvas(this.canvas);
    }

    window.addEventListener('resize', this.drawCanvas);
  }

  componentDidUpdate() {
    this.drawCanvas();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.drawCanvas);
  }

  drawCanvas = () => {
    if (this.canvas) {
      drawOnCanvas(this.canvas);
    }
  };

  logout = e => {
    e.preventDefault();
    window.gapi.auth2.getAuthInstance().signOut();
    this.setState({ viewName: '', properties: [], browserData: [], panes: 6, total: 10000 });
  };

  setViewName = id => {
    const view = this.state.properties.filter(prop => prop.id === id)[0];
    this.setState({ viewName: `${view.name} ${view.propName} ${view.profileName}` });
  };

  getBrowserSizes = id => {
    this.setState({ browserData: [] });

    window.gapi.client
      .request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: `ga:${id}`,
              dateRanges: [
                {
                  startDate: '30daysAgo',
                  endDate: 'yesterday',
                },
              ],
              metrics: [
                {
                  expression: 'ga:sessions',
                },
              ],
              dimensions: [
                {
                  name: 'ga:browserSize',
                },
              ],
              orderBys: [
                {
                  fieldName: 'ga:sessions',
                  sortOrder: 'DESCENDING',
                },
              ],
            },
          ],
        },
      })
      .then(response => {
        const total = response.result.reports[0].data.totals[0].values[0];

        const browserData = sortBy(
          response.result.reports[0].data.rows.map(item => ({
            dimensions: item.dimensions[0],
            occurence: parseInt(item.metrics[0].values[0], 10),
          })),
          'occurence'
        )
          .filter(item => item.dimensions !== '(not set)')
          .reverse();

        this.setViewName(id);
        this.setState({
          total,
          mostOccurence: browserData[0].occurence,
          browserData,
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  handleSlider = e => {
    const panes = parseInt(e.target.value, 10);
    this.setState({ panes });
  };

  handleSort = e => {
    const sortBy = this.state.sortBy === SORTBY.OCCURENCE ? SORTBY.SIZE : SORTBY.OCCURENCE;
    this.setState({ sortBy });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.getBrowserSizes(e.target.value);
  };

  download() {
    const workspace = {
      name: this.state.viewName,
      workspace: this.state.browserData.slice(0, this.state.panes).map(item => ({
        width: parseInt(item.dimensions.split('x')[0], 10),
        height: parseInt(item.dimensions.split('x')[1], 10),
        sizeType: 'px',
        zoom: '1',
        name: `${item.dimensions} (${parseFloat((item.occurence / this.state.total) * 100).toFixed(2)}%)`,
      })),
    };

    const file = new Blob([JSON.stringify(workspace)], { type: 'text/json' });
    return URL.createObjectURL(file);
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Visualize browser sizes"
          keywords={['']}
          description="Show the browser sizes your visitors use. Based on your visitors in the last 30 days from Google analytics."
          ogFileName="visualizebrowsersizes"
          ogCustomPath="/og-custom/"
          pathname={this.props.location.pathname}
          meta={[
            {
              name: `google-signin-client_id`,
              content: '369895063682-4r9hmmhg330bb66mjvutm3k716ki3lu4.apps.googleusercontent.com',
            },
            { name: 'google-signin-scope', content: 'https://www.googleapis.com/auth/analytics.readonly' },
          ]}
        />

        <PageHeader>
          <h1>Visualize browser sizes</h1>
          <h2>
            Show the browser sizes your visitors use. Based on your visitors in the last 30 days from Google analytics.
          </h2>
          <p>
            <Link to="/">Learn more about Polypane</Link> &nbsp;&nbsp;
            <Link to="/create-workspace/">Create workspace from sizes</Link>
          </p>
        </PageHeader>

        <div className={styles.form}>
          <div className={styles.button} id="my-signin2" />
          {this.state.properties.length ? (
            <button className={styles.signout} type="button" onClick={this.logout}>
              Sign out
            </button>
          ) : null}
          <p>
            <em>This web app runs entirely in your browser and no data is shared with Polypane.</em>
          </p>
        </div>

        {this.state.properties.length ? (
          <>
            <div className={styles.form}>
              <div className={styles.selectwrap}>
                <label htmlFor="viewid">
                  Select a Google Analytics profile <em>(account &gt; property &gt; view)</em>
                </label>
                <select onChange={this.handleSubmit}>
                  {this.state.properties.map(p => (
                    <option key={p.id} value={p.id}>
                      {p.name} 🢒 {p.propName} 🢒 {p.profileName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {this.state.browserData.length ? (
              <>
                <div className={styles.canvasTitle}>
                  <p>
                    Darker lines mean more visitors used this size. Sizes used by less than {cutOffPercentage}% of
                    visitors are filtered out. The values on the ruler are for the entire width, so if you see a line at
                    400px, then that pane is 400px wide.
                  </p>
                  <ul className={styles.legend}>
                    {this.state.browserData
                      .reduce(
                        (array, item) => {
                          const width = parseInt(item.dimensions.split('x')[0], 10);
                          const index =
                            width < CATEGORIES.xsmall
                              ? 0
                              : width < CATEGORIES.small
                              ? 1
                              : width < CATEGORIES.medium
                              ? 2
                              : width < CATEGORIES.large
                              ? 3
                              : 4;

                          array[index] += item.occurence;
                          return array;
                        },
                        [0, 0, 0, 0, 0]
                      )
                      .map((item, index) => (
                        <li key={index}>
                          <div className={[styles.color, getCategoryClass(index)].join(' ')} />
                          {getCategoryName(index)} ({parseFloat((item / this.state.total) * 100).toFixed(2)}%)
                        </li>
                      ))}
                  </ul>
                </div>

                <canvas
                  ref={c => {
                    this.canvas = c;
                  }}
                />
                <div
                  className={styles.sizeOverview}
                  style={{
                    height: `${parseInt(
                      sortBy(
                        this.state.browserData.filter(
                          item => item.occurence / this.state.mostOccurence > cutOffPercentage
                        ),
                        item => -parseInt(item.dimensions.split('x')[1], 10)
                      )[0].dimensions.split('x')[1] * zoomFactor,
                      10
                    )}px`,
                  }}
                >
                  {sortBy(
                    [
                      ...this.state.browserData,
                      {
                        dimensions: `${window.innerWidth}x${window.innerHeight}`,
                        occurence: this.state.mostOccurence,
                        thisScreen: true,
                      },
                    ],
                    sortFunction[SORTBY.SIZE]
                  )
                    .filter(item => item.occurence / this.state.mostOccurence > cutOffPercentage)
                    .reverse()
                    .map(item => {
                      const width = parseInt(item.dimensions.split('x')[0], 10);
                      const height = parseInt(item.dimensions.split('x')[1], 10);
                      return (
                        <div
                          key={item.dimensions}
                          className={[
                            styles.dimensions,
                            item.thisScreen ? styles.mine : '',
                            width < CATEGORIES.xsmall
                              ? styles.xsmall
                              : width < CATEGORIES.small
                              ? styles.small
                              : width < CATEGORIES.medium
                              ? styles.medium
                              : width < CATEGORIES.large
                              ? styles.large
                              : styles.xlarge,
                          ].join(' ')}
                          style={{
                            width: `${width}px`,
                            height: `${height}px`,
                            opacity: item.occurence / this.state.mostOccurence,
                          }}
                        >
                          {item.thisScreen ? (
                            <>
                              Your browser size
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {width < CATEGORIES.xsmall
                            ? 'Extra small'
                            : width < CATEGORIES.small
                            ? 'Small'
                            : width < CATEGORIES.medium
                            ? 'Medium'
                            : width < CATEGORIES.large
                            ? 'Large'
                            : 'Extra large'}
                          <br />
                          {item.dimensions}
                          <br />
                          {item.thisScreen ? (
                            ''
                          ) : (
                            <i>{parseFloat((item.occurence / this.state.total) * 100).toFixed(2)}%</i>
                          )}
                        </div>
                      );
                    })}
                </div>
              </>
            ) : null}
          </>
        ) : null}
        <CTA />
        <ExitIntent />
      </Layout>
    );
  }
}

export default Index;
