import { css } from 'astroturf';
import React from 'react';
import { Close } from 'react-bytesize-icons';
import CTA from './CTA-newsletter-exit';

const styles = css`
  .wrap {
    position: fixed;
    background: rgba(220, 220, 220, 0.85) none repeat scroll 0% 0%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    z-index: 100;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    transition: 0.25s ease-in-out all;

    @media (min-width: 30rem) and (min-height: 45rem) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .visible {
    opacity: 1;
    pointer-events: all;
  }
  .bg {
    position: relative;
    margin: 0 auto;
    background: #fff;
    max-width: 42rem;
    padding: 4rem 1rem;

    @media (min-width: 42rem) {
      margin: 4rem auto;
      padding: 0 1rem;
      background: none;
    }

    & button.closebtn {
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 1rem;

      @media (min-width: 42rem) {
        top: -2.75rem;
      }
    }
  }
  .close {

  }
`;

class ExitIntent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showExit: false };
  }

  componentDidMount() {
    setTimeout(() => {
      document.addEventListener('mouseout', this.handleExit);
    }, 20000);
  }

  handleExitClick = () => {
    this.setState({
      showExit: false,
    });

    document.removeEventListener('mouseout', this.handleExit);
  };

  handleExit = (ev) => {
    const e = ev || window.event;
    // Get the current viewport width.
    const vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    // If the current mouse X position is within 50px of the right edge
    // of the viewport, return.
    if (e.clientX >= vpWidth - 50) return;

    // If the current mouse Y position is not within 50px of the top
    // edge of the viewport, return.
    if (e.clientY >= 50) return;

    // Reliable, works on mouse exiting window and
    // user switching active program
    const from = e.relatedTarget || e.toElement;
    if (!from) {
      this.setState({
        showExit: true,
      });
    }
  };

  preventPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { showExit } = this.state;

    return (
      <div className={[styles.wrap, showExit && styles.visible].join(' ')} onClick={this.handleExitClick}>
        <div className={styles.bg} onClick={this.preventPropagation}>
          <CTA />
          <button className={styles.closebtn} aria-label="close modal" onClick={this.handleExitClick} data-tip="Close">
            <Close className={styles.close} width={24} height={24}   />
          </button>
        </div>
      </div>
    );
  }
}

export default ExitIntent;
