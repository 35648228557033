import { css } from 'astroturf';
import React from 'react';
import NewsletterForm from './newsletterForm';

const styles = css`
  .cta {
    color: #000;
    max-width: 30rem;
    margin: 0;
    position: relative;
    text-align: center;
    overflow: hidden;

    @media (min-width: 42rem) {
      border-radius: 1rem;
      box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
        rgba(0, 0, 0, 0.1) 0px 20px 30px;
      color: #fff;
      text-align: left;
      background: #4ca2cd linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
      padding: 3rem;

      & .button {
        color: #fff;
      }
    }

    @media (min-width: 42rem) {
      margin-left: auto;
      margin-right: auto;
    }
    & h3 {
      line-height: 1.2;
      margin-bottom: 1rem;
      font-size: calc(16px + 0.25vw);
      font-weight: 600;
    }

    & p {
      position: relative;
      margin: 0 0 2rem;
    }

    & form {
      margin-left: auto;
      margin-right: auto;
    }

    & .button {
      pointer-events: all;
      margin: 1rem 0;
      font-size: calc(16px + 0.4vw);
      border-bottom: 1px solid #000;
      line-height: 1.25;
      padding: 0.33em 0;
      display: inline-block;
      text-decoration: none;
      line-height: 26px;
      transition: all 0.25s ease-in-out;
      border-radius: 0;
      box-shadow: initial;
      font-weight: 800;

      @media (min-width: 600px) {
        font-size: 22px;
      }

      &::after {
        position: absolute;
        right: 0px;
        bottom: -1px;
        content: ' ';
        width: 100%;
        height: 1px;
        background: #fff;
        transition: 0.25s ease-out width;
      }

      &:hocus {
        color: #000;
        text-decoration: none;
        background: none;

        & svg {
          transform: translateX(4px);
        }

        &::after {
          width: 0;
        }
      }

      & svg {
        vertical-align: bottom;
      }
    }
  }
`;

export default () => (
  <div className={styles.cta}>
    <small>Before you go&hellip;</small>
    <h3>
      <strong>If you want to be kept up to date with new articles,</strong> join our newsletter!
    </h3>
    <p>We'll send an email about once a month with new articles, tools and extra content.</p>
    <NewsletterForm />
  </div>
);
